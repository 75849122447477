<template>
  <div
    :class="[
      {
        'zcollapse': !hideBorder,
        'zcollapse-fullwidth': fullWidth,
        'zcollapse-open': isCollapseOpen,
        'zcollapse-padded': padded,
      },
    ]"
  >
    <div
      v-if="hasTriggerContent"
      :class="['zcollapse-trigger', { 'zcollapse-trigger-disabled': isTriggerDisabled }]"
      @click="toggle"
    >
      <div><slot name="trigger" /></div>
      <fa
        v-if="!isTriggerDisabled"
        :icon="['fas', 'chevron-down']"
        class="transition-transform"
        :class="{ 'rotate-180': isCollapseOpen }"
      />
    </div>
    <div
      ref="collapse"
      :class="[
        'zcollapse-content',
        { 'zcollapse-content-fullwidth': fullWidth, 'zcollapse-content-notrigger': isTriggerDisabled },
        contentClass,
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { COLLAPSE_GROUP_INJECTION_KEY } from './constants'
import type { CollapseGroupInjectionPayload } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  visible?: boolean
  disableTrigger?: boolean
  fullWidth?: boolean
  padded?: boolean
  contentClass?: string
  hideBorder?: boolean
}>(), {
  visible: false,
  disableTrigger: false,
  fullWidth: false,
  paddded: false,
  contentClass: '',
  hideBorder: false,
})

const id = useId()
const { accordion, openCollapseId, updateOpenCollapseId } = inject<CollapseGroupInjectionPayload>(COLLAPSE_GROUP_INJECTION_KEY, {
  accordion: false,
  openCollapseId: ref(''),
  updateOpenCollapseId: (_: string) => { },
})

const showCollapseContent = ref<boolean>(props.visible)

const slots = useSlots()
const hasTriggerContent = computed(() => Boolean(slots.trigger))

const isTriggerDisabled = computed(() => !hasTriggerContent.value || props.disableTrigger)

const isCollapseOpen = computed(() => {
  if (accordion) {
    return openCollapseId?.value === id
  }
  // if no trigger, rely on visible prop
  return isTriggerDisabled.value ? props.visible : showCollapseContent.value
})

function toggle() {
  if (accordion) {
    updateOpenCollapseId(id)
  }

  showCollapseContent.value = !showCollapseContent.value
}
</script>
